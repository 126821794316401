

function footer() {
    return (
        <div style={{
            textAlign: "center",
            padding: "10px",
            }}>
            <p style={{color: "#f2edd7"}}>Matthew Williams 2021 ™</p>
        </div>
    )
}
export default footer;